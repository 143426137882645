<template>
	<div id="loading-vendafacil-loading-container-base">
		<div id="loading-vendafacil-loading-overlay">
			<div id="loading-vendafacil-loading-center-title-container-aguarde">
				<div id="loading-vendafacil-loading-center-title-aguarde">Aguarde...</div>
			</div>
			<div id="loading-vendafacil-loading-center-spinner">
				<div id="loading-vendafacil-spinner">
					<div id="loading-vendafacil-double-bounce1"></div>
					<div id="loading-vendafacil-double-bounce2"></div>
				</div>
			</div>
			<div id="loading-vendafacil-loading-title-container">
				<div id="loading-vendafacil-loading-title-linxmicrovix">App Profissionais - Microvix Beauty</div>
				<div id="loading-vendafacil-loading-title-vendafacil" v-if="nomeAplicacao">{{ nomeAplicacao }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loading',
	props: ['nomeAplicacao']
};
</script>

<style scoped>
#loading-vendafacil-loading-container-base {
	position: fixed; /*position: absolute;*/
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100%;
	z-index: 99999;
}
#loading-vendafacil-loading-center-title-container-aguarde {
	position: relative;
	top: 35%;
	text-align: center;
}
#loading-vendafacil-loading-center-title-aguarde {
	color: #fff;
	font-family: Dosis, sans-serif;
	font-weight: bold;
	font-size: 2rem;
}
#loading-vendafacil-loading-overlay {
	margin: 0 auto;
	display: flex;
	height: 100vh;
	width: 100%;
	flex-direction: column;
	background-color: var(--cinza-escuro); /*background-color: rgba(0, 0, 0, 0.8);*/
	overflow: hidden;
}
#loading-vendafacil-loading-center-spinner {
	position: relative;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	height: 60px;
	width: 60px;
}
#loading-vendafacil-loading-title-container {
	position: relative;
	top: 40%;
}
#loading-vendafacil-loading-title-linxmicrovix {
	text-align: center;
	font-family: Dosis, sans-serif;
	font-size: 2rem;
	color: #ffb200;
	height: 35px;
}
#loading-vendafacil-loading-title-vendafacil {
	text-align: center;
	font-family: Dosis, sans-serif;
	font-weight: bold;
	font-size: 2rem;
	color: #fff;
}
#loading-vendafacil-spinner {
	width: 80px;
	height: 80px;
}
#loading-vendafacil-double-bounce1,
#loading-vendafacil-double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #333;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}
#loading-vendafacil-double-bounce1 {
	background-color: #ffb200;
}
#loading-vendafacil-double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
	background-color: #fff;
}
@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}
@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
</style>
