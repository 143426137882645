import Swal from 'sweetalert2';
import stringApi from '@/core/api/stringApi';

const _baseMessage = (message, title, customClass = '') => {
	return {
		title: title,
		text: message,
		html:message,
		customClass: {
			confirmButton: 'btn btn-primary',
			cancelButton: 'btn btn-default',
			denyButton: 'btn btn-danger',
			popup: customClass
		},
		buttonsStyling: false
	}
};

const _baseMessageIcon = (message, title, icon, customClass) => {
	let baseMessage = _baseMessage(message, title, customClass);
	baseMessage.icon = icon;

  return baseMessage;
};

const _baseToast = (timer = 3000) => {
  return Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
};

export const _toastTimerByMessage = (message) => {
  const wordCount = stringApi.wordCount(message);
  if (wordCount <= 1) {
    return 3000;
  }

  return wordCount * 1000;
};

const _warning = (message, title = 'Alerta', removerIcone = false, customClass) => {
	let baseMessage = _baseMessageIcon(message, title, removerIcone ? null : 'warning', customClass);
	Swal.fire(baseMessage);
}

const _info = (message, title = 'Informação', removerIcone = false) => {
  let baseMessage = _baseMessage(message, title);
  Swal.fire(baseMessage);
};

const _success = (message, title = 'Sucesso', removerIcone = false) => {
  let baseMessage = _baseMessageIcon(message, title, removerIcone ? null : 'success');
  Swal.fire(baseMessage);
};

const _error = (message, title = 'Ops!', removerIcone = false) => {
  let baseMessage = _baseMessageIcon(message, title, removerIcone ? null : 'error');
  Swal.fire(baseMessage);
};

const _question = async (message, title = 'Confirmação', removerIcone = false, apenasOk = false) => {
  let baseMessage = {
    ..._baseMessageIcon(message, title, removerIcone ? null : 'question'),
    confirmButtonText: apenasOk ? 'Ok' : 'Sim',
    denyButtonText: 'Não',
    cancelButtonText: 'Cancelar',
    showCancelButton: false,
    showCloseButton: !apenasOk,
    showConfirmButton: true,
    showDenyButton: !apenasOk,
  };

  return new Promise((resolve, reject) => {
    Swal.fire(baseMessage)
      .then((result) => {
        if (result.isConfirmed) {
          return resolve(result);
        } else {
          if (result.isDismissed) {
            result.closed = true;
          }

          return reject(result);
        }
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

const _successNotification = (message, timer) => {
  const timerAux = timer ? timer : _toastTimerByMessage(message);
  const toast = _baseToast(timerAux);

  toast.fire({
    icon: 'success',
    title: message,
  });
};

const _warningNotification = (message, timer) => {
  const timerAux = timer ? timer : _toastTimerByMessage(message);
  const toast = _baseToast(timerAux);

  toast.fire({
    icon: 'warning',
    title: message,
  });
};

const messageBox = {
  info: _info,
  error: _error,
  question: _question,
  success: _success,
  warning: _warning,
  successNotification: _successNotification,
  warningNotification: _warningNotification,
};

export default messageBox;
