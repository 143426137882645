import axios from 'axios';
import apiConfig from './apiConfig';

const apiClient = axios.create({
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
		'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
	},
	withCredentials: false,
});

const HTTP_UNAUTHORIZED = 401;
const HTTP_BADREQUEST = 400;

apiClient.interceptors.request.use(
	(config) => {
		const token = apiConfig.getToken();
		if (token && config.headers['Authorization'] == null)
			config.headers['Authorization'] = token;

		return config;
	},

	(error) => {
		return Promise.reject(error);
	}
);

apiClient.interceptors.response.use(
	(response) => response,
	(error) => {
		if (!error.response) {
			return Promise.reject(error);
		}

		if (error.message === "Network Error") {
			//TODO: avisar ao usuário que ele pode estar sem internet
		}

		if (error.response.status === HTTP_UNAUTHORIZED) {
			// TODO: UNAUTHORIZED, IMPLEMENTAREMOS REFRESH TOKEN? REDIRECIONAMOS PARA LOGIN OU APRESENTAREMOS MENSAGEM, PERGUNTANDO SE DESEJA LOGAR NOVAMENTE?
			return Promise.reject(error);
		}
		else if (error.response.status === HTTP_BADREQUEST) {
			// TODO: BAD REQUEST, CONTROLAREMOS NO APICLIENT OU RETORNA PARA A SERVICE/COMPONENT TRATAREM O RETORNO?
			return Promise.reject(error);
		}
		else {
			return Promise.reject(error);
		}
	}
);

export default apiClient;
