import apiConfig from "@/core/http/apiConfig";
import apiClient from "@/core/http/apiClient";
import { NOMES_PRODUTOS_API } from "@/core/http/apiTypes";

const loginPorToken = (token) => {
	const urlRequest = apiConfig.getUrlErpAdminApi() + "/api/Autenticacao/LoginPorToken";
	const request = { Produtos: NOMES_PRODUTOS_API };
	const idConfigAmbiente = apiConfig.getIdConfigAmbiente();

	if (idConfigAmbiente) {
		request.IdAmbienteProdutos = idConfigAmbiente;
	}

	return new Promise((resolve, reject) => {
		apiClient
			.post(urlRequest, request, { headers: { AuthToken: token } })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			});
	});
}

const login = (login, senha) => {
	const urlRequest = apiConfig.getUrlErpAdminApi() + "/api/Autenticacao/Login";
	const request = { Login: login, Senha: senha };

	return new Promise((resolve, reject) => {
		apiClient
			.post(urlRequest, request)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			});
	});
}

const selecionarEmpresa = (idPortal, idEmpresa, authToken) => {
	return new Promise((resolve, reject) => {
		const urlRequest = apiConfig.getUrlErpAdminApi() + "/api/Autenticacao/SelecionarEmpresa";
		const idConfigAmbiente = apiConfig.getIdConfigAmbiente();
		const request = { IdPortal: idPortal, IdEmpresa: idEmpresa, Produtos: NOMES_PRODUTOS_API };

		if (idConfigAmbiente) {
			request.IdAmbienteProdutos = idConfigAmbiente;
		}

		apiClient
			.post(urlRequest, request, { headers: { AuthToken: authToken } })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			});
	});
};

const adminApiService = {
	login,
	loginPorToken,
	selecionarEmpresa
}

export default adminApiService;