export default {
    
    getSessaoAppProfissional() {
        return localStorage.getItem('sessaoAppProfissional');
    },
    
    setSessaoAppProfissional(sessaoAppProfissional) {
        if (sessaoAppProfissional)
            localStorage.setItem('sessaoAppProfissional', sessaoAppProfissional);
        else
            localStorage.removeItem('sessaoAppProfissional');
    },
    
    setSessaoMicrovix(sessaoMicrovix) {
        if (sessaoMicrovix)
            localStorage.setItem('sessaoMicrovix', sessaoMicrovix);
        else
            localStorage.removeItem('sessaoMicrovix');
    },
    
    getSessaoMicrovix() {
        return localStorage.getItem('sessaoMicrovix');
    }
}
