import { reactive, readonly } from 'vue';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	isLoading: false,
};

let _state = reactive(cloneDeep(initialState));

let uiState = readonly(_state);

const uiActions = {
	exibir() {
		_state.isLoading = true;
	},

	ocultar(timeout) {
		if (timeout > 0) {
			setTimeout(() => {
				_state.isLoading = false;
			}, timeout);
		} else {
			_state.isLoading = false;
		}
	}
};

export {
	uiState,
	uiActions
};