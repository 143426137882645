import { initialState } from '@/modules/admin/store/adminState';

const autenticar = (state, auth) => {
	if (!auth) return;

	if (auth.Portal) {
		state.Portal = auth.Portal;
	}

	if (auth.Empresa) {
		state.Empresa = auth.Empresa;
	}

	if (auth.Usuario) {
		state.Usuario = auth.Usuario;
	}

	if (auth.TokenMcx) {
		state.TokenMcx = auth.TokenMcx;
	} else if (auth.TokenTemporario) {
		state.TokenMcx = auth.TokenTemporario;
	}

	if (auth.TokenTemporario) {
		state.TokenMcx = auth.TokenTemporario;
	}

	if (auth.TokenJWT) {
		state.TokenJwt = auth.TokenJWT;
		state.Autenticado = true;
	}
};

const reset = (state) => {
	Object.assign(state, initialState);
};

export default {
	autenticar,
	reset
};