
const STORAGEKEY_TOKEN = 'token_api';
const STORAGEKEY_TOKEN_ANONIMO = 'token_api_anonimo';
const STORAGEKEY_URL_ERP = 'url_erp_base';
const STORAGEKEY_URL_API_PROFISSIONAIS = 'url_app_profissionais_api';

const urlErpBaseDev = process.env.VUE_APP_URL_ERP_BASE;
const urlProfissionaisApiDev = process.env.VUE_APP_URL_PROFISSIONAISAPI;

const idConfigAmbiente = process.env.VUE_APP_ID_CONFIG_AMBIENTE || null;

const apiConfig = {
	getToken: () => localStorage.getItem(STORAGEKEY_TOKEN) || '',
	getTokenAnonimo: () => localStorage.getItem(STORAGEKEY_TOKEN_ANONIMO) || '',	
	getUrlBaseErp: () => urlErpBaseDev || localStorage.getItem(STORAGEKEY_URL_ERP) || '',
	getUrlProfissionaisApi: () => urlProfissionaisApiDev || localStorage.getItem(STORAGEKEY_URL_API_PROFISSIONAIS) || '',
	getUrlErpAdminApi: () => process.env.VUE_APP_URL_ERPADMIN,	
	getIdConfigAmbiente: () => idConfigAmbiente,

	setUrlErpBase: (val) => {
		if (!val)
			localStorage.removeItem(STORAGEKEY_URL_ERP);
		else
			localStorage.setItem(STORAGEKEY_URL_ERP, val);
	},

	setToken: (val) => {
		if (!val)
			localStorage.removeItem(STORAGEKEY_TOKEN);
		else
			localStorage.setItem(STORAGEKEY_TOKEN, val);
	},

	setTokenAnonimo: (val) => {
		if (!val)
			localStorage.removeItem(STORAGEKEY_TOKEN_ANONIMO);
		else
			localStorage.setItem(STORAGEKEY_TOKEN_ANONIMO, val);
	},

	setUrlProfissionaisApi: (val) => {
		if (!val)
			localStorage.removeItem(STORAGEKEY_URL_API_PROFISSIONAIS);
		else
			localStorage.setItem(STORAGEKEY_URL_API_PROFISSIONAIS, val);
	},

	reset: () => {
		localStorage.removeItem(STORAGEKEY_TOKEN);
		localStorage.removeItem(STORAGEKEY_URL_API_PROFISSIONAIS);
	}

};

export default apiConfig;