<template>
  <div>
    <div class="footbar">
      <div class="icon-comission icons-footbar">
        <font-awesome-icon @click="abrirComissioes()" :class="{ 'selected-icon': selectedIcon === 'hand-holding-usd' }"
          icon="hand-holding-usd" />
      </div>
      <div class="icon-calendar icons-footbar">
        <font-awesome-icon @click="abrirAgendamentosProfissional()" :class="{ 'selected-icon': selectedIcon === 'calendar-alt' }"
          icon="calendar-alt" />
      </div>
      <div class="icons-footbar">
        <font-awesome-icon @click="abrirBloqueios()" :class="{ 'selected-icon': selectedIcon === 'lock' }" icon="lock" />
      </div>
    </div>
  </div>
</template>

<script>
import messageBox from "@/common/messagebox";
import * as mutations from "@/plugins/mutations.nomes";

export default {
  name: "FootbarApp",

  data: () => ({
    nomeProfissionalSessao: "",
    cpfProfissionalSessao: "",
    selectedIcon: null
  }),
  methods: {

    toCamelCase(str) {
      if (str == undefined) {
        return "";
      }
      if (str.length === 0) {
        return "";
      }

      const words = str.split(/-|_/);
      for (let i = 1; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join("");
    },

    abrirMenu() {
      this.selectedIcon = "calendar";
      // this.$router.push({
      //   name: 'Menu'
      // });
    },

    Logoff() {
      this.selectedIcon = "lock";
    //  messageBox
        // .question("Confirmação", "Deseja realmente sair ?")
        // .then(() => {
        //   this.efetivarLogoff();
        // })
        // .catch(() => { });
    },
    
    efetivarLogoff() {
      this.$store.commit(mutations.LOGOUT_MICROVIX);
      this.$store.commit(mutations.LOGOUT_APP_PROFISSIONAL);
      this.$router.push({ name: 'LoginMicrovix', params: { logoff: true } });
    },

    abrirComissioes() {
      this.selectedIcon = "hand-holding-usd";
      this.$router.push({ name: 'Comissoes' });
    },

    abrirBloqueios() {
      this.selectedIcon = "lock";
      this.$router.push({ name: 'BloqueioAgendamento' });
    },

    abrirAgendamentosProfissional() {
      this.selectedIcon = "calendar-alt";
      this.$router.push({ name: 'AgendaProfissional' });
    },
  },
};
</script>

<style scoped>
.icons-footbar {
  width: 28px;
  height: 28px;
  color: #bdbdbd; 
}

.icon-comission {
  width: 33%;
}

.selected-icon {
  color: #411e5a;
}


.icon-calendar {
  width: 33%;
}

.footbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 16px;
  height: 56px;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .footbar {
    justify-content: center;
  }
}
</style>