<template>
  <v-card class="mx-auto"  rounded="0">
    <div class="div-info d-flex align-center">
      <v-avatar @click="menuUser()" class="mt-2 ml-2 mb-2" size="48">
        <v-img cover :src="perfilImageUrl ? perfilImageUrl : require('@/assets/Images/user-profile.jpg')"></v-img>
      </v-avatar>
      <div class="ml-5">
        <div class="text-white">
          <span class="text-name-profissional">{{ toCamelCase(this.nomeProfissionalSessao) }}</span>
        </div>
        <div class="text-white">
          <span class="text-cpf-profissional ">{{ this.cpfProfissionalSessao }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>

export default {
    name: "NavbarUsuario",


  watch: {
    
    "$store.state.sessaoAppProfissional.sessao.Cpf": {
      handler() {
        this.getCpfProfissionalSessao();
      },
      immediate: true,
    },

    "$store.state.sessaoAppProfissional.sessao.Nome": {
      handler() {
        this.getNomeProfissionalSessao();
      },
      immediate: true,
    },

  },

  mounted() { },

  data: () => ({
    nomeProfissionalSessao: "Carregando...",
    cpfProfissionalSessao:"..."
  }),

  computed: {
        perfilImageUrl() {
            return this.$store.state.perfilImageUrl;
        },
    },
  
  methods: {

    toCamelCase(str) {
      
      if (str == undefined || str.length === 0) return "";      

      const words = str.split(/-|_/);
      
      for (let i = 1; i < words.length; i++)
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      
      return words.join("");
    },
    
    menuUser() {
      this.$router.push({
        name: 'Menu'
      });
    },
    
    isUserLoggedIn() {
      const microvixLogado = this.$store.state?.sessaoMicrovix;
      const profissionalLogado = this.$store.state?.sessaoAppProfissional;

      if (microvixLogado == undefined || microvixLogado == null) {
        return this.$router.push({
          name: 'LoginMicrovix'
        });
      } else if (profissionalLogado == undefined && profissionalLogado == null) {
        return this.$router.push({
          name: 'loginAppProfissional'
        });
      } 
    },
    
    getNomeProfissionalSessao() {
      this.nomeProfissionalSessao = this.$store.state?.sessaoAppProfissional?.sessao?.Nome;
          },
    
    getCpfProfissionalSessao(){
      this.cpfProfissionalSessao = this.$store.state?.sessaoAppProfissional?.sessao?.Cpf;
    },
  },
};
</script>

<style scoped>

.text-cpf-profissional{
  color: var(--content-content-oncolor, #FFF);
font-size: 15px;
font-family: Roboto;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: -0.5px;
}
.text-name-profissional{
  color: var(--content-content-oncolor, #FFF);
text-align: center;
font-size: 16px;
font-family: Roboto;
font-style: normal;
font-weight: 600;
line-height: 20px;
letter-spacing: -0.5px;
}

.label,
label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.mx-auto {
  height: auto;
  background-color: #411e5a;
  border-color: #411e5a;
}


.div-info{
  margin-top: 18px;
  margin-left: 10px;
}

</style>