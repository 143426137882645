import apiConfig from "@/core/http/apiConfig";

const adminService = {
	registrarUrlAPIs(produtos) {
		
		apiConfig.setUrlProfissionaisApi(
			produtos.filter((x) => x.Produto === "LMX.AppProfissionaisServicos.WebApi")[0].Url
		);

		var url = (window.location != window.parent.location)
			? document.referrer
			: document.location.href;

		apiConfig.setUrlErpBase(url);
	},

	registrarTokens(tokens) {
		apiConfig.setToken(tokens.TokenJWT);
	}
}

export default adminService;