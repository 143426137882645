import * as nomes from './mutations.nomes'
import sessionAppProfissional from "@/common/session.app.profissional";

export default { 
  
  [nomes.LOGIN_APP_PROFISSIONAL](state, payload) {
    state.sessaoAppProfissional = payload;
  },
  
  [nomes.LOGOUT_APP_PROFISSIONAL](state) {
    sessionAppProfissional.setSessaoAppProfissional(null);
    state.sessaoAppProfissional = {autenticado: false, parametros: null, sessao: null };
  },
  
  [nomes.LOGIN_MICROVIX](state, payload) {
    state.sessaoMicrovix = payload;
  },
  
  [nomes.LOGOUT_MICROVIX](state) {
    sessionAppProfissional.setSessaoMicrovix(null);
    state.sessaoMicrovix = {login: null, senha: null};
  },
  [nomes.SET_PERFIL_IMAGE_URL](state, url) {
    state.perfilImageUrl = url;
  },
};
