import adminActions from '@/modules/admin/store/adminActions';
import adminGetters from '@/modules/admin/store/adminGetters';
import adminMutations from '@/modules/admin/store/adminMutations';
import { state } from '@/modules/admin/store/adminState';

export default {
	namespaced: true,
	state,
	getters: adminGetters,
	mutations: adminMutations,
	actions: adminActions
}