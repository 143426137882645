export const initialState = {
	Autenticado: false,
	Empresa: null,
	Portal: null,
	Usuario: null,
	TokenJwt: null,
	TokenMcx: null,
	ModoDesenvolvimento: process.env.NODE_ENV !== 'production'
};

export const state = () => (initialState);