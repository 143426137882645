import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
import adminStore from '@/modules/admin/store/adminStore';
import mutations from '@/plugins/mutations';

const vuexLocal = new VuexPersistence({
	storage: window.sessionStorage,
	key: 'app-profissional-store'
});

export default createStore({
	state: {
		perfilImageUrl: null,
	},
	mutations,
	actions: {
		resetAllState() { },
	},
	modules: {
		admin: adminStore
	},
	plugins: [vuexLocal.plugin],
	getters: {
		
		sessaoAppProfissional(state){
		  return state.sessaoAppProfissional;
		},

		sessaoMicrovix(state){
			return state.sessaoMicrovix;
		},
		perfilImageUrl(state) {
			return state.perfilImageUrl;
		  },

	  },
})
