import { createRouter, createWebHistory } from 'vue-router'
import { authenticationMiddleware, beforeAuthMiddleware } from "./middleware";
import { routesProfissional } from "@/router/routes";

const routesAdmin = () => {
	return [
		{
			path: '/',
			redirect: process.env.NODE_ENV !== 'production' ? '/rotas' : '/paginaNaoEncontrada'
		},
		{
			path: '/loading',
			name: 'loading',
			component: () => import('@/components/Loading.vue'),
			props: route => ({ nomeAplicacao: route.query.nomeAplicacao }),
			meta: { publico: true }
		},
		{
			path: '/rotas',
			name: 'Rotas',
			component: () => import('@/modules/admin/components/Rotas.vue'),
			meta: { publico: true }
		},
		{
			path: '/naoAutenticado',
			name: 'NaoAutenticado',
			component: () => import('@/modules/admin/components/NaoAutenticado.vue'),
			meta: { publico: true }
		},
		{
			path: '/login',
			name: 'Login',
			component: () => import('@/modules/admin/components/Login.vue'),
			meta: { publico: true }
		},
		{
			path: '/logout',
			name: 'Logout',
			component: () => import('@/modules/admin/components/Logout.vue'),
			meta: { publico: true }
		},
		{
			path: '/paginaNaoEncontrada',
			name: 'PaginaNaoEncontrada',
			component: () => import("@/modules/admin/components/Pagina404.vue"),
			meta: { publico: true }
		},
		/*{
			path: '/loginvuetify',
			name: 'LoginVuetify',
			component: () => import("@/modules/profissional/components/LoginVuetify.vue"),
			meta: { publico: true }
		}*/
	];
};

const getRoutes = () => {
	const routes = [...routesAdmin(), ...routesProfissional()];
	return routes;
};

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: getRoutes()
});

router.beforeEach(authenticationMiddleware);
router.afterEach(beforeAuthMiddleware);

export default router;