// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import colors from 'vuetify/lib/util/colors'
import { VDatePicker } from 'vuetify/labs/VDatePicker'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'


export default createVuetify(
  {
    theme: {
      primary: "#5b2e90",
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#5b2e90",
            //primary: colors.red.darken1, // #E53935
            secondary: colors.red.lighten4, // #FFCDD2
            
          }
        },
      },
    },
    display: {
      mobileBreakpoint: 'sm',
      thresholds: {
        xs: 0,
        sm: 340,
        md: 540,
        lg: 800,
        xl: 1280,
      },
    },
    components:{
      VDatePicker,
      VSkeletonLoader
    },
    date:{ },
  }
)
