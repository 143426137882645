import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import vueTheMask from 'vue-the-mask';

import router from './router';
import store from './store/appStore';
import vtooltip from 'v-tooltip';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'v-tooltip/dist/v-tooltip.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLock);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vtooltip);
app.use(vuetify);
app.use(vueTheMask);

app.component('font-awesome-icon', FontAwesomeIcon);  

loadFonts();

app.mount('#app');
