import store from "@/store/appStore";
import { uiActions } from '@/store/uiStore';
const authenticationMiddleware = (to, from, next) => {
	let rotaPublica = to.meta.publico;

	if (!to.name) {
		return next({ name: "PaginaNaoEncontrada" });
	}

	let authToken = to.query.authToken;
	if (!authToken) {
		authToken = to.query.auth;
	}

	let adminState = store.state.admin;
	if (adminState.Autenticado) {
		if (!authToken) {
			return next();
		}

		if (adminState.TokenMcx == authToken) {
			return next();
		}
	}

	if (!authToken) {
		if (rotaPublica) {
			return next();
		} else {
			return next({ name: "NaoAutenticado" });
		}
	}

	uiActions.exibir();
	store.dispatch("admin/autenticarToken", authToken)
		.then(() => {
			return next();
		})
		.catch((err) => {
			console.error(err);
			return next({ name: "NaoAutenticado" });
		});
};

const beforeAuthMiddleware = () => {
	uiActions.ocultar();
};

export {
	beforeAuthMiddleware,
	authenticationMiddleware
};
