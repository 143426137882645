const autenticacaoUsuarioESenhaRealizada = (state) => {
	return ((state.Usuario) && (!state.Autenticado));
}

const autenticado = (state) => {
	return state.Autenticado;
}

const usuario = (state) => {
	return state.Usuario;
}

export default {
	usuario,
	autenticado,
	autenticacaoUsuarioESenhaRealizada
};