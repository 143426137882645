import apiConfig from "@/core/http/apiConfig";
import adminService from '@/modules/admin/services/adminService';
import adminApiService from '@/modules/admin/services/adminApiService';

const autenticarToken = ({ commit, state }, token) => {
	return new Promise((resolve, reject) => {
		adminApiService.loginPorToken(token)
			.then((auth) => {
				auth.TokenMcx = token;

				commit('autenticar', auth);

				if (state.Autenticado) {
					adminService.registrarTokens(auth);
					adminService.registrarUrlAPIs(auth.Produtos);
					return resolve();
				} else {
					return reject("Falha ao autenticar");
				}

			})
			.catch((err) => {
				return reject(err);
			});
	});
};

const selecionarEmpresa = ({ commit, state }, payload) => {
	return new Promise((resolve, reject) => {
		adminApiService.selecionarEmpresa(payload.Portal.Id, payload.Empresa.Id, state.TokenMcx)
			.then((auth) => {
				payload.Portal.Empresas = [];
				auth.Portal = payload.Portal;
				auth.Empresa = payload.Empresa;
				commit('autenticar', auth);

				if (state.Autenticado) {
					adminService.registrarTokens(auth);
					adminService.registrarUrlAPIs(auth.Produtos);
					return resolve();
				} else {
					return reject("Falha ao autenticar");
				}
			})
			.catch((err) => {
				return reject(err);
			});
	});
};

const login = ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		adminApiService.login(payload.Login, payload.Senha)
			.then((auth) => {
				commit('autenticar', auth);

				return resolve(auth);
			})
			.catch((err) => {
				return reject(err);
			});
	});
};

const logout = ({ commit, dispatch }) => {
	apiConfig.reset();
	commit("reset");
	dispatch("resetAllState", null, { root: true });
};

export default {
	login,
	logout,
	autenticarToken,
	selecionarEmpresa
};