import store from "@/store/appStore";

const sessaoMicrovix = store.getters["sessaoMicrovix"];

export const routesProfissional = () => {
	return [
		{
			path: '/loginAppProfissional',
			name: 'LoginAppProfissional',
			component: () => import('@/modules/profissional/components/LoginAppProfissional.vue'),
			meta: {
				config: (sessaoMicrovix) => {
					if (sessaoMicrovix.login && sessaoMicrovix.senha) { 
						return {
							publico: true
						};
					}
				}
			}
		},
		{
			path: '/loginMicrovix/',
			name: 'LoginMicrovix',
			component: () => import('@/modules/profissional/components/LoginMicrovix.vue'),
			meta: { publico: true }
		},
		{
			path: '/menu/:logoff?',
			name: 'Menu',
			component: () => import('@/components/Menu.vue'),
		},
		{
			path: '/ComissoesHome/:logoff?',
			name: 'Comissoes',
			props: true,
			component: () => import('@/modules/comissoes/components/ComissoesResumo.vue'),
		},
		{
			path: '/comissoes/detalhes/:dataInicio/:dataFim/:logoff?',
			name: 'ComissoesDetalhes',
			props: true,
			component: () => import('@/modules/comissoes/components/ComissoesDetalhes.vue'),

		},
		{
			path: '/agenda/BloqueioAgendamento/:logoff?',
			name: 'BloqueioAgendamento',
			component: () => import('@/modules/agenda/components/BloqueioAgendamento.vue'),
		},
		{
			path: '/agenda/AgendaProfissional/:logoff?',
			name: 'AgendaProfissional',
			component: () => import('@/modules/agenda/components/AgendaProfissional.vue'),
		},
		  
	];
};