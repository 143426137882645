const _getOnlyNumbers = (txt) => {
	if (!txt || txt == "") {
		return "";
	}
	return txt.replace(/\D/g, "");
};

function _verificarSeString(string) {
    return Object.prototype.toString.call(string) === "[object String]"
}

const stringApi = {
	getOnlyNumbers: _getOnlyNumbers,
    verificarSeString: _verificarSeString
}

export default stringApi;