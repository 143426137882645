<template>
	<NavbarUserInfo v-if="showUserInfo"/>
		<v-app>
			<Loading v-if="isLoading" />
			<v-main>
				<router-view/>
			</v-main>
		</v-app>
	<FootbarApp v-if="showUserInfo"/>
</template>
  
  <script>
  import { computed } from 'vue';
  import { uiState } from '@/store/uiStore';
  import Loading from '@/components/Loading.vue';
  import { useDisplay } from 'vuetify';
  import FootbarApp from '@/components/FootbarApp.vue';
  import NavbarUserInfo from "@/components/NavbarUsuario.vue";
  import { useRouter } from 'vue-router'; 
  
  export default {
	name: 'App',
	
	components: { Loading, NavbarUserInfo, FootbarApp },

	setup() {
	  const { mobile } = useDisplay();
	  const router = useRouter(); 
	  
	  const showUserInfo = computed(() => {
		return (
		  router.currentRoute.value.name !== 'Rotas' &&
		  router.currentRoute.value.name !== 'Login' &&
		  router.currentRoute.value.name !== 'LoginMicrovix' &&
		  router.currentRoute.value.name !== 'loginAppProfissional' &&
		  router.currentRoute.value.name !== 'Logout' &&
		  router.currentRoute.value.name !== 'NaoAutenticado' &&
		  router.currentRoute.value.name !== 'loading' &&
		  router.currentRoute.value.name !== 'PaginaNaoEncontrada' &&
		  router.currentRoute.value.name !== 'Menu'
		);
	  });
  
	  return {
		isLoading: computed(() => uiState.isLoading),
		mobile,
		showUserInfo
	  };
	}
  };
  </script>
  
  
<style>
@import '../src/assets/LinxBootstrap4/bootstrap-dist/css/bootstrap-grid.min.css';
@import '../src/assets/LinxBootstrap4/bootstrap-dist/css/bootstrap.min.css';
@import '../src/assets/LinxBootstrap4/linx-bootstrap-v4.min.css';
@import '../src/assets/LinxFonts/linx-icon.css';
@import '../src/assets/css/app.css';
@import '../src/assets/css/chrome54Support.css';
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500');

#app,
body,
html {
	height: 100% !important;
	background-color: #f1f3f6;
}
</style>
